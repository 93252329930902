<template>
  <div class="localP">
    <div class="loginBox" ref="loginBox">
      <div class="box_left">
        <div class="center_txt1">
          
          <span v-show="!pageindex" style="margin-left: 1vw"
            >济源新能智运平台</span
          >
          <span v-show="pageindex" style="margin-left: 1vw"
            >济源新能智运平台</span
          >
        </div>
        <div class="center_txt2">
          <span class="border_lt"></span>
          <span class="border_rt"></span>
          <span>欢迎使用</span>
          <span class="border_rb"></span>
          <span class="border_lb"></span>
        </div>
        <div class="center_text3">
          <h3>服务内容</h3>
          <p>济源新能智运平台为货场提货作业的车队、司机提供基于互联网的在线派车、预约 等业务操作，同时为方便客户作业，提供导航、定位等辅助功能。济源新能智运平台致力于提升物流效率，优化物流方案，减少车辆碳排放，为客户提供更优质的服务。</p>
        </div>
      </div>
      <div class="box_right">
        <div class="right_line1">
          <span class="font1">系统登录</span>
          <span class="font2" @click="pageindex=!pageindex">
              {{pageindex?'切换到管理后台':'切换到车队端'}}
          </span>
        </div>
        <div v-show="!pageindex" class="right_line2">
          <div class="input_box">
            <m-input
              v-model="useinfo.username"
              :fontname="'icon-zhanghao'"
              :info="'请填写用户名'"
            ></m-input>
          </div>
          <div class="input_box">
            <m-input
              v-model="useinfo.password"
              :fontname="'icon-mima'"
              :itype="'password'"
              :info="'请填写密码'"
            ></m-input>
          </div>
          <div class="input_box">
            <div class="input_code">
              <m-input
                v-model="useinfo.captcha"
                :info="'请填写验证码'"
                :alone="'width:100%'"
              ></m-input>
            </div>
            <div class="box_image" ref="imgs"></div>
            <i class="iconfont icon-shuaxin" @click="getCode"></i>
          </div>
          <div class="input_box">
            <div class="radio_box" @click="checked = !checked">
              <span :class="checked ? 'ischecked box' : 'box'">
                <i class="iconfont icon-xuanzhong"></i
              ></span>
              <span class="text">记住密码</span>
            </div>
          </div>
          <div class="input_box">
            <div class="islogin" @click="jumphome">
              <span>登录</span>
            </div>
          </div>
          <div class="botton_sty">
            <span class="paswd">忘记密码?</span>
            <div class="register">
              <span style="color: #acafb8">没有账号?</span>
              <span style="text-decoration: underline">立即注册</span>
            </div>
          </div>
        </div>
        <div v-show="pageindex" class="right_line2">
          <div class="input_box codesty">
            <div class="islogin">
              <a class="ahref" href="https://fleet.yhwlps.com/">
                <span class="txt">登录</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="urlp">
      <img class="img" src="../assets/imgs/gongan.png">
      <a href="https://beian.miit.gov.cn">津公网安备 12019302000146号 津ICP备2021009189号</a>
    </div>
  </div>
</template>
<script>
import mInput from "@/components/mInput.vue";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      pageindex: true,
      useinfo: {
        captcha: "",
        username: "",
        password: "",
        uuid: null,
      },
      checked: false,
    };
  },
  components: {
    mInput,
  },
  mounted() {
    this.setloginBoxSty();
    this.getCode();
  },
  methods: {
    ...mapMutations(["adduser"]),
    // 计算登录窗体的宽高
    setloginBoxSty() {
      let width = window.screen.width * 0.6;
      let height = width * 0.42;
      this.$refs.loginBox.style.width = width + "px";
      this.$refs.loginBox.style.height = height + "px";
    },
    async getCode() {
      this.useinfo.captcha = "";
      this.useinfo.uuid = this.getUUID();
      const { data } = await this.$http("api1", "/captcha.jpg", "get", {
        uuid: this.useinfo.uuid,
      });
      if (!data) return;
      // 将arraybuffer转化为图片
      let binary = "";
      const bytes = new Uint8Array(data);
      const len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      this.$refs.imgs.style = `background:url(data:image/png;base64,${window.btoa(
        binary
      )});background-size: 100% 100%;`;
    },
    getUUID() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        return (
          c === "x" ? (Math.random() * 16) | 0 : "r&0x3" | "0x8"
        ).toString(16);
      });
    },
    async jumphome() {
      if (
        this.useinfo.captcha &&
        this.useinfo.username &&
        this.useinfo.password
      ) {
        const { data } = await this.$http(
          "api1",
          "/sys/login",
          "post",
          this.useinfo
        );
        if (data.msg != "success") {
          this.$message({
            type: "warning",
            title: "提示",
            message: data.msg,
          });
          this.getCode();
          return;
        }
        let obj = {
          token: data.token,
          username: this.useinfo.username,
        };
        this.adduser(obj);
        // if(this.checked) document.cookie=`username=${this.useinfo.username};password=${this.useinfo.password};expires=${new Date().getTime()+30*60*60*1000};path=/`;
        this.$router.push("/home/homepage");
      } else {
        this.$message({
          type: "warning",
          title: "提示",
          message: "请查看用户名、密码、验证码是否填写",
        });
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.localP {
  background: #edf2f4;
  .loginBox {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    background: #fff;
    overflow: hidden;
    .box_left {
      position: relative;
      height: 100%;
      width: 57%;
      background-image: url("../assets/imgs/login2.png");
      background-size: 100% 100%;
      .center_txt1 {
        position: absolute;
        left: 50%;
        top: 20%;
        transform: translate(-50%, -50%);
        font-size: 1.5rem;
        letter-spacing: 0.1em;
        white-space: nowrap;
        color: #fff;
        img {
          width: 12%;
          vertical-align: middle;
        }
      }
      .center_txt2 {
        position: absolute;
        left: 50%;
        bottom: 50%;
        font-size: 1rem;
        color: #fff;
        transform: translate(-50%, -50%);
        padding: 1%;
        letter-spacing: 0.1em;
        .border_lt,
        .border_rt,
        .border_rb,
        .border_lb {
          position: absolute;
          width: 12%;
          padding-bottom: 10%;
        }
        .border_lt {
          top: 0;
          left: 0;
          border-left: 1px solid #fff;
          border-top: 1px solid #fff;
        }
        .border_rt {
          right: 0;
          top: 0;
          border-top: 1px solid #fff;
          border-right: 1px solid #fff;
        }
        .border_rb {
          bottom: 0;
          right: 0;
          border-bottom: 1px solid #fff;
          border-right: 1px solid #fff;
        }
        .border_lb {
          bottom: 0;
          left: 0;
          border-bottom: 1px solid #fff;
          border-left: 1px solid #fff;
        }
      }
      .center_text3{
        position: absolute;
        left: 50%;
        width: 80%;
        transform: translateX(-50%);
        bottom: 10%;
        color: #fff;
        p{
          font-size: 80%;
        }
      }
    }
    .box_right {
      position: absolute;
      box-sizing: border-box;
      top: 0;
      right: 0;
      height: 100%;
      width: 43%;
      padding: 0 4%;
      overflow: auto;
      .right_line1 {
        position: relative;
        width: 100%;
        height: 10%;
        .font1,
        .font2 {
          position: absolute;
          bottom: 0;
        }
        .font1 {
          font-size: 1.2rem;
        }
        .font2 {
          right: 0;
          cursor: pointer;
          font-size: 0.9rem;
          color: #acafb8;
        }
        .font2:hover {
          color: #15357d;
        }
      }
      .right_line2 {
        position: relative;
        box-sizing: border-box;
        padding: 5% 0;
        height: 90%;
        width: 100%;
        .input_box {
          position: relative;
          height: 10%;
          width: 100%;
          margin-bottom: 7.2%;
          .input_code {
            position: absolute;
            height: 100%;
            width: 66%;
          }
          .box_image {
            position: absolute;
            right: 0;
            height: 100%;
            width: 30%;
            border: 1px solid #e8e8e8;
          }
          .icon-shuaxin {
            position: absolute;
            right: 0;
            top: 50%;
            font-size: 1rem;
            cursor: pointer;
            transform: translate(120%, -50%);
            color: #acafb8;
          }
          .keeppassword {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 90%;
            color: #acafb8;
          }
          .radio_box {
            position: relative;
            cursor: pointer;
            height: 100%;
            font-size: 90%;
            .box {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              width: 13px;
              height: 13px;
              border: 1px solid #e8e8e8;
            }
            .text {
              position: absolute;
              top: 50%;
              left: 23px;
              transform: translateY(-50%);
            }
            .icon-xuanzhong {
              position: absolute;
              top: 50%;
              left: 50%;
              font-size: 60%;
              transform: translate(-50%, -50%);
              color: #fff;
            }
            .ischecked {
              border-color: #093697;
              background: #093697;
            }
          }
        }
        .codesty{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
        .islogin {
          position: relative;
          height: 100%;
          width: 100%;
          background: linear-gradient(50deg, #093094, #0e8cc6);
          text-align: center;
          color: #fff;
          font-size: 0.9rem;
          .ahref{
            position: relative;
            display: inline-block;
            height: 100%;
            width: 100%;
            color: #fff;
            .txt{
              position: absolute;
              top: 50%;
              left: 50%;
              white-space: nowrap;
              transform: translate(-50%,-50%);
            }
          }
          cursor: pointer;
          span {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      .botton_sty {
        position: relative;
        width: 100%;
        .register,
        .paswd {
          position: absolute;
          color: #1a2a54;
          white-space: nowrap;
          font-size: 0.9rem;
          cursor: pointer;
        }
        .register {
          right: 0;
        }
      }
    }
  }
  .urlp {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    bottom: 100px;
    .img{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    a {
      padding-left: 30px;
      text-decoration: none;
      color: #acafb8;
      letter-spacing: 0.1em;
    }
    a:hover,a:active{
       color: #15357d;
    }
  }
}
</style>