<template>
  <div class="minput">
      <div v-if="!alone" :class="pitchOn?'left pitchon_color':'left'">
          <i :class="pitchOn?['iconfont ',fontname,'pitchon_color']:['iconfont '+fontname]"></i>
      </div>
      <div :class="pitchOn?'right pitchon_color':'right'" :style="alone">
         <div v-if="!alone" :class="pitchOn?'rotate_box pitchon_color':'rotate_box'"></div>
         <input :type="itype" :value="value" @blur="nopitchOn">
      </div>
      <div v-if="reminder" class="textR">{{reminder}}</div>
  </div>
</template>
<script>
export default {
    props:{
        itype:{
            type:String,
            default(){
                return "text"
            }
        },
        info:{
            type:String
        },
        value:{
            type:String
        },
        fontname:{
            type:String
        },
        alone:{
            type:String,
            default(){
                return ""
            }
        }
    },
    data(){
        return{
            pitchOn:false,
            reminder:false
        }
    },
    methods:{
        nopitchOn(event){
            this.pitchOn=false;
            this.$emit("input",event.target.value);
            if(event.target.value){
                this.reminder="";
            }else{
                this.reminder=this.info;

            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .minput{
        position: relative;
        height: 100%;
        width: 100%;
        .left{
            position: absolute;
            box-sizing: border-box;
            width: 18%;
            height: 100%;
            border: 1px solid #E8E8E8;
            border-right: none;
            background: #fff;
        }
        .iconfont{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            font-size: 0.9rem;
            color: #ACAFB8;
        }
        .right{
            position: absolute;
            box-sizing: border-box;
            right: 0;
            width: 82%;
            height: 100%;
            border: 1px solid #E8E8E8;
            input{
                box-sizing: border-box;
                height: 100%;
                width: 100%;
                padding-left: 1.5rem;
                font-size: 0.8rem;
                border: none;
                outline: none;
            }
        }
        .rotate_box{
            position: absolute;
            z-index: 2;
            box-sizing: border-box;
            width: 5%;
            height: 0;
            padding-bottom: 5%;
            border: 1px solid #E8E8E8;
            left: 0;
            top: 50%;
            background: #fff;
            border-left: none;
            border-bottom: none;
            transform: translate(-50%,-50%) rotateZ(45deg);
        }
        .textR{
            position: absolute;
            bottom: 0;
            width: 100%;
            color: red;
            font-size: 0.8rem;
            letter-spacing: 0.1rem;
            transform: translateY(100%);
        }
        .pitchon_color{
            border-color: #093697;
            color: #093697;
        }
    }
</style>